<mat-drawer-container>

    <mat-drawer mode="side" #drawer [opened]="!isMobileMenu || isMenuOpen" >
        <div style="display: flex; justify-content: center; padding-top: 1.5rem;">
            <img style="width: 35px;" src="assets/img/favicon.png" alt="">
        </div>

        <mat-nav-list class="nav-list">
            <a class="nav-list-a" routerLink="/" routerLinkActive="nav-list-active" [routerLinkActiveOptions]="{exact:true}">
              <img class="nav-list-img" src="assets/icons/home.svg" alt="">
                <span i18n class="nav-list-text">Home</span>
            </a>
            
            <a class="nav-list-a"  routerLink="/admin/accounts" routerLinkActive="nav-list-active" [routerLinkActiveOptions]="{exact:true}"
              *ngIf="isAdmin">
              <img class="nav-list-img" src="assets/icons/admin.svg" alt="">
              <span i18n class="nav-list-text">Admin</span>
            </a>

            <a [class]="isModuleActive('historic_module') ? 'flex nav-list-a' : 'hidden' " routerLink="/{{activeRepository}}/harvesting" routerLinkActive="nav-list-active" [routerLinkActiveOptions]="{exact:true}">
              <img class="nav-list-img" src="assets/icons/chart.svg" alt="">
              <span i18n class="nav-list-text">Harvestings</span>
            </a>

            <a [class]="isModuleActive('validation_module') ? 'flex nav-list-a' : 'hidden' "  routerLink="/{{activeRepository}}/validation/{{harvestingConentId}}" routerLinkActive="nav-list-active" [routerLinkActiveOptions]="{exact:true}">
              <img class="nav-list-img" src="assets/icons/validation.svg" alt="">
              <span i18n class="nav-list-text">Validation</span>
            </a>

            <a [class]="isModuleActive('statistics_module') ? 'flex nav-list-a' : 'hidden' " routerLink="/statistics/statistics" routerLinkActive="nav-list-active" [routerLinkActiveOptions]="{exact:true}">
              <img class="nav-list-img" src="assets/icons/statistics.svg" alt="">
              <span i18n class="nav-list-text">Statistics</span>
            </a>
            
            
            
            


            <a class="nav-list-a nav-list-a-user"  routerLink="/user/profile" routerLinkActive="nav-list-active" [routerLinkActiveOptions]="{exact:true}">
              <img class="nav-list-img" src="assets/icons/user.svg" alt="">
              <span i18n class="nav-list-text">Account</span>
            </a>
            <a class="nav-list-a nav-list-a-logout" (click)="logout()">
              <img class="nav-list-img" src="assets/icons/user-logout.svg" alt="">
              <span class="nav-list-text">Logout</span>
            </a>
        </mat-nav-list>

    </mat-drawer>

</mat-drawer-container>
