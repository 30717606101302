<div class="home-section">
  <div class="home-info">
    <div class="home-info-container">
      <div class="home-info-top animate__animated animate__fadeInLeft animate__faster">
        <h1 class="font-weight-bold home-title" i18n>Welcome</h1>
        <p i18n>Dashboard for repository managers</p>
      </div>
      <div class="home-info-icons-container animate__animated animate__fadeInLeft animate__faster">
        <img style="width: 40px;" src="assets/icons/home-mobil-data.svg" alt="">
        <img style="width: 40px;" src="assets/icons/home-mobil-stadistic.svg" alt="">
        <img style="width: 40px;" src="assets/icons/home-mobil-chart.svg" alt="">
        <img style="width: 40px;" src="assets/icons/home-mobil-stadistic2.svg" alt="">
      </div>
      <div class="home-info-bottom animate__animated animate__fadeInLeft animate__faster">
        <h4 i18n>What's on the platform?</h4>
        <ul>
          <li i18n>Information about the <b>harvesting</b> of deposit documents.</li>
          <li i18n>Information about the <b>documents validation.</b></li>
          <li i18n>Informative <b>statistics and graphics.</b></li>
        </ul>
      </div>
    </div>
    <div class="home-section-image-container">
      <img class="animate__animated animate__fadeInRight animate__faster" src="assets/img/home-section-image-2.png" alt="">
    </div>
  </div>
  <mat-card class="home-services animate__animated animate__fadeInDown animate__faster">
    <mat-card-title>
      <h2 i18n>Services</h2>
    </mat-card-title>
    <div class="home-services-card-container">

      <a [class]="isModuleActive('historic_module') ? 'services-item' : 'services-item-inprogress'" (click)="onHarvestingNavigate()">
        <img style="width: 24px; opacity: 0.7;" [class]="isModuleActive('historic_module') ? 'opacity-100' : 'opacity-0'" src="assets/icons/chart.svg" alt=""> 
        <span i18n [class]="isModuleActive('historic_module') ? 'opacity-100' : 'opacity-0'" >Data harvesting</span>
      </a>

      <a [class]="isModuleActive('validation_module') ? 'services-item' : 'services-item-inprogress'" (click)="onValidationNavigate()">
        <img style="width: 24px; opacity: 0.7;" [class]="isModuleActive('validation_module') ? 'opacity-100' : 'opacity-0'" src="assets/icons/validation.svg" alt=""> 
        <span i18n [class]="isModuleActive('validation_module') ? 'opacity-100' : 'opacity-0'" >Validations</span>
      </a>

      <a [class]="isModuleActive('statistics_module') ? 'services-item' : 'services-item-inprogress'" (click)="onStatisticsNavigate()">
        <img style="width: 24px; opacity: 0.7;" [class]="isModuleActive('statistics_module') ? 'opacity-100' : 'opacity-0'" src="assets/icons/statistics.svg" alt=""> 
        <span i18n [class]="isModuleActive('statistics_module') ? 'opacity-100' : 'opacity-0'">Usage statistics</span>
      </a>

      <a class="services-item-inprogress" >
      </a>
    </div>
  </mat-card>
</div>
<div class="home-footer" >
  <h6 style="margin-bottom: 0; margin-right: 8px;" i18n>Powered by</h6>
  <img style="width: 180px;" src="assets/img/logo-footer.png" alt="">
</div>

