
<div class="header-component">
    <div>
        <div style="display: flex; justify-content: space-between;">
            <div class="menu-container" *ngIf="isLoading">
                <h6>Loading dashboard...</h6>
            </div>
            <div class="header-burguer-menu" (click)="toggleMenu()">
                <img style="width: 25px; cursor: pointer;" src="assets/icons/menu-burguer.svg" alt="">
            </div>
            <div *ngIf="!isLoading && menuList && menuList.length <= 1"
                class="menu-container">
                <h6>Dashboard / {{activeRepository}}</h6>
            </div>
            <div *ngIf="!isLoading && menuList && menuList.length >= 2"
                    class="menu-container"
                    style="cursor: pointer">
            <p-autoComplete 
                [(ngModel)]="selectedMenu" 
                [dropdown]="true" 
                [suggestions]="filteredMenus" 
                (completeMethod)="filterMenu($event)" 
                field="name"
                (onSelect)="onSelectItem($event)"
                [placeholder]="activeRepository"
            ></p-autoComplete>
            </div>
            <div class="header-logo-referencia">
                <h6 style="margin-bottom: 0; margin-right: 8px;" i18n>Powered by</h6>
                <img style="width: 140px" 
                    src="assets/img/logo-footer.png" alt="">
            </div>
        </div>
    </div>
</div>
  
